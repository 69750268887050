import React from "react"

const Staff = () => {
  return <>
    <article className="content">
      <div className="container">
        <h1 className="bar">スタッフ紹介</h1>
      </div>
    </article>
  </>;
}

export default Staff;